import { useReadQuery } from '@apollo/client';
import { useAppLoader } from './loader';

/**
 * Suspends and reads all data for current user
 *
 */
export const useMe = () => {
  const data = useAppLoader();

  const {
    data: { me },
  } = useReadQuery(data.meQueryRef);

  return me;
};
