import { Tokens } from './types';

export const getAuthInfo = (tokens: Tokens | undefined) => {
  if (!tokens) {
    return null;
  }

  const username = tokens.idToken.payload.preferred_username || '';

  return {
    id: tokens.idToken.payload.sub as string,
    fullName: tokens.idToken.payload.name || username,
    username,
  };
};

export type AuthInfo = NonNullable<ReturnType<typeof getAuthInfo>>;
