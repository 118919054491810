import { gql } from '@/__generated__';
import { AuthInfo, getAuthInfo } from '@/amplify/get-auth-info';
import { preloadQuery } from '@/apollo/apollo-client';
import { getAuthorizedData } from '@/routes-utils/handle-authorized-data';
import { setupAppGlobals } from '@/setup-app-globals';
import { useRouteLoaderData } from 'react-router';

export const UnreadNotificationsQuery = gql(`
  query UnreadNotificationsQuery($limit: Int!, $read: Boolean!) {
    notifications(read: $read, limit: $limit, nextToken: null) {
      items {
        id
      }
    }
  }
`);

export const meQuery = gql(`
  query meQuery {
    me {
      id
      username
      fullName
      createdAt
      bio
      protectedFields {
        email
        isOnboardingFinished
        onboardingProgress
        openAIKey {
          enabled
          key
          baseUrl
          availableModels
        }
        interests
        possibleUsernames
        intercomHmac
        stripeSellerAccount {
          ...StripeSellerProfileFragment
        }
      }
      avatar {
        key
      }
    }
  }
`);

const flagsQuery = gql(`
  query flagsQuery {
    flags
  }
`);

export const appLoader = async () => {
  setupAppGlobals();

  const { tokens } = await getAuthorizedData();

  const authInfo = getAuthInfo(tokens);

  if (authInfo) {
    return authenticatedRootLoader(authInfo);
  } else {
    return { authenticated: false };
  }
};

const authenticatedRootLoader = (authInfo: AuthInfo) => {
  return {
    authenticated: true,
    unreadNotificationsQueryRef: preloadQuery(UnreadNotificationsQuery, {
      variables: { limit: 10, read: false },
    }),
    meQueryRef: preloadQuery(meQuery),
    flagsQueryRef: preloadQuery(flagsQuery),
    authInfo,
  };
};

type LoaderData = ReturnType<typeof authenticatedRootLoader>;

export const useAppLoader = () =>
  useRouteLoaderData('routes/_app') as LoaderData;
